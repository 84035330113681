import React, { useState } from 'react';
import { string, oneOfType } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { getUserAvailability, getIsVettedUser, getIsClient, getIsConsultant } from '../../util/user';
import { availabilities } from '../../util/availabilities';
import { timeDiffAsString } from './utils';

import { AvatarLarge, Button, H4, IconCheckmark, IconDelete, IconEdit, LineDivider, Modal, NamedLink, ReviewRating } from '../../components';
import css from './AuthorCard.module.css';

import CalendarIcon from './icons/CalendarIcon';
import CheckIcon from './icons/CheckIcon';
import PercentageIcon from './icons/PercentageIcon';
import StarEmtyIcon from './icons/StarEmtyIcon';
import StarFullIcon from './icons/StarFullIcon';
import DotsIcon from './icons/DotsIcon';
import PostedIcon from './icons/PostedIcon';
import VerifiedIcon from './icons/VerifiedIcon';
import ClockIcon from './icons/ClockIcon';
import LocationIcon from './icons/LocationIcon';
import SwitchIcon from './icons/SwitchIcon';
import PaperPlaneIcon from './icons/PaperPlaneIcon';
import PaymentIcon from './icons/PaymentIcon';
import confirmed from "./images/confirmed.png";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createSlug, LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';
import { closeListing } from '../../containers/ManageListingsPage/ManageListingsPage.duck';
import { useDispatch } from 'react-redux';

const AuthorCard = props => {
  const {
    rootClassName,
    className,
    postedDate,
    author,
    intl,
    currentUser,
    authorIsConsultant,
    authorIsClient,
    isProfilePage,
    isSearchPage,
    totalBids,
    isBidAccepted,
    currentListing
  } = props;

  const [isFavorite, setIsFavorite] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const userAvailability = getUserAvailability(author);
  const isVettedUser = getIsVettedUser(author);
  const isClient = getIsClient(author);
  const isConsultant = getIsConsultant(author);
  const [isShowActionPopup, setShowActionPopup] = useState(false)
  const [confirmBidOpen, setConfirmBidOpen] = useState(false)
  const [confirmBidOpensuccess, setConfirmBidOpenSuccess] = useState(false)

  const handleAddToFavorits = () => {
    setIsFavorite(!isFavorite);
  };

  // const consultantYearsExperience = '17 years expericence';
  // const consultantRecurringClients = '3 Recuring clients';
  // const consultantActiveLast = 'Active 3 hrs ago';
  // const consultantLastDelivery = 'Last delivery 2 days ago';
  // const consultantCompletedGigs = '15+ completed gigs';
  // const consultantJobSucces = '98% job success';
  // const consultantRate = '$74/hr rate';

  const country = author?.attributes?.profile?.publicData?.companyAddress?.country;
  const onManageDisableScrolling = (componentId, scrollingDisabled = true) => {
    // We are just checking the value for now
    console.log('Toggling Modal - scrollingDisabled currently:', componentId, scrollingDisabled);
  };

  const history=useHistory()
  const { title = '' } = currentListing.attributes;

  const slug = createSlug(title);
  const dispatch =useDispatch()
const handleCloseListing=async()=>{
 await  dispatch(closeListing(currentListing.id))
}
  return (
    <div className={classes}>
      <div className={css.column}>
        {/* <div
          className={css.row}
          style={{ justifyContent: 'flex-end', gap: 10, paddingBottom: '20px' }}
        > */}
        {/* {isFavorite ? (
            <div onClick={handleAddToFavorits} style={{ cursor: 'pointer' }}>
              <StarFullIcon />
            </div>
          ) : (
            <div onClick={handleAddToFavorits} style={{ cursor: 'pointer' }}>
              <StarEmtyIcon />
            </div>
          )} */}

        {/* <div style={{ cursor: 'pointer' }}>
            <DotsIcon />
          </div> */}
        {/* </div> */}

        <div className={css.row}>
          <AvatarLarge className={css.avatar} user={author} />
          <div className={css.row} style={{ justifyContent: 'space-between', width: '100%' }}>
            <div className={css.column}>
              <div className={css.row}>
                <p className={css.name}>{author?.attributes?.profile?.displayName}</p>
                {isVettedUser && <VerifiedIcon />}

              </div>
              <p className={css.company}>
                {author?.attributes?.profile?.publicData?.headline}
                {author?.attributes?.profile?.publicData?.companyProfile?.companyName}
              </p>
            </div>
            <div className={css.column} style={{ alignSelf: 'flex-start' }}>
              {isConsultant ? (
                <div className={css.tags}>
                  {userAvailability ? (
                    userAvailability === availabilities.available ? (
                      <span className={css.availableTag}>
                        <FormattedMessage id="AuthorCard.tags.available" />
                      </span>
                    ) : (
                      <span className={css.notAvailableTag}>
                        <FormattedMessage id="AuthorCard.tags.notAvailable" />
                      </span>
                    )
                  ) : null}
                  {/* <span className={css.topRatedTag}>
                    <FormattedMessage id="AuthorCard.tags.topRated" />
                  </span> */}
                </div>
              ) : isClient ? (
                <div className={css.tags}>
                  {userAvailability ? (
                    userAvailability === availabilities.available ? (
                      <span className={css.availableTag}>
                        <FormattedMessage id="AuthorCard.tags.available" />
                      </span>
                    ) : (
                      <span className={css.notAvailableTag}>
                        <FormattedMessage id="AuthorCard.tags.onLeave" />
                      </span>
                    )
                  ) : null}
                  {/* <span className={css.topRatedTag}>
                    <FormattedMessage id="AuthorCard.tags.topRated" />
                  </span> */}
                  <span className={css.bidsTag}>
                    <FormattedMessage id="AuthorCard.tags.bids" values={{ bids: totalBids }}/>
                  </span>
                  <div className={css.dotsIcon} style={{ cursor: 'pointer' }} onClick={() => setShowActionPopup(prev => !prev)}>
                    <DotsIcon />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={css.row} style={{ justifyContent: 'space-between' }}>
          {/* <div className={css.row}>
            <ReviewRating rating={4} reviewStarClassName={css.stars} />

            <p className={css.ratingHighlight}>
              <FormattedMessage
                id="AuthorCard.ratingsCount"
                values={{
                  ratingsCount: author?.attributes?.profile?.publicData?.rating
                    ? author?.attributes?.profile?.publicData?.rating
                    : // : 0,
                    '1.6K',
                }}
              />
            </p>

            <p className={css.rating}>
              <FormattedMessage id="AuthorCard.ratings" />
            </p>
          </div> */}
          {!isProfilePage &&
            <div className={css.row}>
              <PostedIcon />
              <p className={css.posted}>{timeDiffAsString(postedDate, intl)}</p>
            </div>
          }
        </div>
        {isConsultant && isProfilePage && (
          <div className={css.row} style={{ flexWrap: 'wrap', paddingTop: 20 }}>
            {/* <span className={css.info}>
              <CalendarIcon />
              {consultantYearsExperience}
            </span> */}
            {/* <span className={css.info}>
              <SwitchIcon />
              {consultantRecurringClients}
            </span> */}
            {/* <span className={css.info}>
              <ClockIcon />
              {consultantActiveLast}
            </span> */}
            {/* <span className={css.info}>
              <PaperPlaneIcon />
              {consultantLastDelivery}
            </span> */}
            {/* <span className={css.info}>
              <PaymentIcon />
              {consultantRate}
            </span> */}
            {/* <span className={css.info}>
              <CheckIcon />
              {consultantCompletedGigs}
            </span> */}
            {/* <span className={css.info}>
              <PercentageIcon />
              {consultantJobSucces}
            </span> */}
          </div>
        )}

        {authorIsConsultant && isSearchPage && (
          <div className={css.row}>
            {/* <span className={css.info}>
              <CalendarIcon />
              {consultantYearsExperience}
            </span> */}
            {/* <span className={css.info}>
              <CheckIcon />
              {consultantCompletedGigs}
            </span> */}
            {/* <span className={css.info}>
              <PercentageIcon />
              {consultantJobSucces}
            </span> */}
          </div>
        )}

        {authorIsClient && isSearchPage && (
          <div>
            <div className={css.row}>
              {/* <span className={css.info}>
                <CheckIcon />
                15+ completed gigs
              </span> */}
              <span className={css.info}>
                <LocationIcon />
                {country}
              </span>
              {/* <span className={css.info}>
                <ClockIcon />
                {matchingGigDuration && (
                  <FormattedMessage id={`gigDurations.${matchingGigDuration.key}`} />
                )}
              </span> */}
            </div>
          </div>
        )}
      </div>
      {isShowActionPopup &&
        <div className={css.ActionPopup}>
          <NamedLink
            className={classNames(css.manageLink,{[css.disable]: isBidAccepted})}
            name="EditListingPage"
            params={{ id:currentListing.id.uuid, slug, type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: 'description' }}
          >
           
         
          <div className={classNames(css.edit, css.popUpModel)} >
            <div><IconEdit /></div>
            <div><FormattedMessage id="ShowGigs.edit" /></div>
          </div>
          </NamedLink>
          <div className={classNames(css.delete, css.popUpModel,{[css.disable]: isBidAccepted})}
          onClick={() => {
            setConfirmBidOpen(prev => !prev)
            setShowActionPopup(false)
          }}>
            <div><IconDelete /> </div>
            <div><FormattedMessage id="ShowGigs.delete" /></div>
          </div>
        </div>
      }
      <Modal
        id="ConfirmedBid"
        isOpen={confirmBidOpen}
        onClose={() => {
          setConfirmBidOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal={true}
        className={classNames(css.confirmDeletePopup, css.customePopUps)}
      >
        <div className={css.innerPopUp}>
        <H4 as="h2" className={css.modalTitle}>
          <FormattedMessage id="ShowGigs.deleteModel.title" />
        </H4>
        <p className={css.info}>
          <FormattedMessage id="ShowGigs.deleteModel.desc" />
        </p>

        <LineDivider />

        <div className={classNames(css.buttonMain)}>
         
          <div className={css.buttons}>
          <Button
              onClick={e => {
                e.preventDefault();
                setConfirmBidOpen(false);
                setConfirmBidOpenSuccess(true)
                handleCloseListing()
              }}
              className={css.withBorderBtn}
            >
              <FormattedMessage id="ShowGigs.deleteModel.accept" />
            </Button>
            <Button
              onClick={e => {
                e.preventDefault();
                setConfirmBidOpen(false);
              }}
            >
              <FormattedMessage id="ShowGigs.deleteModel.reject" />
            </Button>
          </div>
        </div>
        </div>
      </Modal>

      <Modal
        id="ConfirmedBid"
        isOpen={confirmBidOpensuccess}
        onClose={() => {
          setConfirmBidOpenSuccess(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal={true}
        className={classNames(css.confirmDeletePopup,css.customePopUps)}

      >
  <div className={css.innerPopUp}>
        <div className={css.confirmedIconContainer}>
          <img src={confirmed} alt="Confirmed" />
        </div>
        <LineDivider />


        <H4 as="h2" className={css.modalTitle}>
          <FormattedMessage id="ShowGigs.deleteModelSuccess.title" />
        </H4>
        <p className={classNames(css.info, css.deleteSucessfully)}>
          <FormattedMessage id="ShowGigs.deleteModelSuccess.desc" />
        </p>

        <LineDivider />

        <div className={classNames(css.buttonMain)}>
        
          <div className={css.buttons}>
          <Button
              onClick={e => {
                e.preventDefault();
                setConfirmBidOpen(false);
                setConfirmBidOpenSuccess(false);
                history.push('/listings')
              }}
              className={css.withBorderBtn}
            >
              <FormattedMessage id="ShowGigs.deleteModelSuccess.reject" />
            </Button>
            <Button
              onClick={e => {
                e.preventDefault();
                setConfirmBidOpen(false);
                setConfirmBidOpenSuccess(false);
                history.push('/l/new')

              }}
            >
              <FormattedMessage id="ShowGigs.deleteModelSuccess.accept" />
            </Button>

             
          </div>
        </div>
        </div>
      </Modal>

    </div>
  );
};

AuthorCard.defaultProps = {
  rootClassName: null,
  className: null,
  // user: null,
  author: null,
  currentUser: null,
};

AuthorCard.propTypes = {
  rootClassName: string,
  className: string,
  // user: oneOfType([propTypes.user, propTypes.currentUser]),
  author: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
};

export default AuthorCard;
